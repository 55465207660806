import React, { useEffect, useMemo, useState } from 'react'

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import NumberSelectionCard from '@components/NumberSelectionCard';
import UCPagination from '@components/theme/UCPagination';
import UCPlaceholder from '@components/theme/UCPlaceholder';

import useApi from '@hooks/useApi';

const ITEMS_PER_PAGE = 10;
// xajah97737@joeroc.com
// hegoye2515@mposhop.com

const ChannelSelectionForm = () => {
    const { t } = useTranslation();
    const { getRequest, data, loading } = useApi();
    const [currentPage, setCurrentPage] = useState(1);
    const [numbers, setNumbers] = useState([]);
    const { control, register, formState: { errors }, setValue, getValues, watch } = useFormContext();
    const [isSmsEnabled, setIsSmsEnabled] = useState(getValues()?.smsEnable || false);
    const getPersistNumber = getValues()?.number;

    const { field } = useController({
        name: 'number',
        control,
        defaultValue: getValues('number'),
        rules: {
            required: t('form_validations.select_at_least_one_number'),
            validate: value => value !== '0' || t('form_validations.select_at_least_one_number'),
        },
    });

    useEffect(() => {
        getRequest({ url: 'v1/numbers/available-number' });
    }, []);

    useEffect(() => {
        if (data && data?.length > 0) {
            setNumbers(data);
        }
    }, [data, numbers]);

    const paginatedNumbers = useMemo(() => {
        const indexOfLastNumber = currentPage * ITEMS_PER_PAGE;
        const indexOfFirstNumber = indexOfLastNumber - ITEMS_PER_PAGE;
        return numbers.slice(indexOfFirstNumber, indexOfLastNumber);
    }, [currentPage, numbers]);


    const handleRadioChange = (Id, number, checked) => {
        setValue('number', number);
        setValue('trunkId', Id);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleSmsEnableChange = (e) => {
        setIsSmsEnabled(e.target.checked);
    }

    const renderNumberSelection = useMemo(() => {
        if (!loading && paginatedNumbers.length > 0) {
            // const numberChunk = numbers?.slice(
            //     (currentPage === 1 ? 0 : currentPage - 1) * data?.pageSize,
            //     data?.pageSize * currentPage
            // );
            return paginatedNumbers.map((item, index) => (
                <div className='col' key={item?.Id}>
                    <div className="" key={index}>
                        <NumberSelectionCard
                            number={item?.ExternalNumber}
                            checked={getValues()?.trunkId == item?.Id}
                            {...field}
                            onCheckboxChange={
                                (index, checked) => handleRadioChange(item?.Id, item?.ExternalNumber, checked)
                            }
                        />
                    </div>
                </div>
            ));
        }
    }, [currentPage, data, loading, numbers, getPersistNumber]);

    return (
        <>
            {/* number Selection */}
            <div className="card p-3 mb-3">
                <div className="mb-3">
                    <label>
                        <span className="form-check-label">
                            {t('form_headers.number_select')}
                        </span>
                        <span className="form-check-description">
                            <p>
                                {t('form_helpers.number_select')}
                            </p>
                        </span>
                    </label>
                </div>
                <>
                    {data ? <>
                        <div className="row mb-3 row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3 gy-2">
                            {renderNumberSelection}
                        </div>
                        {errors.number?.message && <div className="alert alert-danger">{errors.number?.message}</div>}
                    </> : <UCPlaceholder wrapperClasses={'mb-4'} innerClasses={'w-100 h-num-placeholder'} />}
                </>
                {
                    !loading && data?.length > 0 && <UCPagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(data?.length / ITEMS_PER_PAGE)}
                        onPageChange={handlePageChange} />
                }
            </div>
            {/* sms config */}
            <div className="card p-3">
                <div className="mb-3">
                    <label className="form-check">
                        <input className="form-check-input" type="checkbox" name="smsEnable"
                            {...register('smsEnable')} onChange={handleSmsEnableChange} />
                        <span className="form-check-label">
                            {t('form_headers.enable_sms')}
                        </span>
                        <span className="form-check-description">
                            <p>
                                {t('form_helpers.enable_sms')}
                            </p>
                        </span>
                    </label>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-6">
                        <label className="form-label">{t('form_fields.sms_mask.label')}</label>
                        <input
                            type="text"
                            className={`form-control ${errors.smsMask && 'is-invalid'}`}
                            placeholder={t('form_fields.sms_mask.placeholder')}
                            name="smsMask"
                            {...register('smsMask', {
                                required: watch('smsEnable'),
                            })}
                            disabled={!isSmsEnabled}
                        />
                        {errors.smsMask && <div className="invalid-feedback">
                            {t('form_validations.required')}
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChannelSelectionForm